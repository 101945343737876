;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"1.9.0"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

import packageJson from './package.json';

console.log('SENTRY_ENVIRONMENT', process.env.SENTRY_ENVIRONMENT);
if (process.env.SENTRY_ENVIRONMENT === 'prod') {
  Sentry.init({
    dsn: 'https://b2ea35cb08536dd7ca6b07d83b040bdc@o4507866247069696.ingest.us.sentry.io/4508394464280576',
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    release: packageJson.version,
    integrations: [],
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
